import { createTheme } from '@mui/material/styles';
import React from 'react';

declare module '@mui/material/styles' {
    interface TypographyVariants {
        customH1: React.CSSProperties;
        customH2: React.CSSProperties;
        customH3: React.CSSProperties;
        customSubtitle: React.CSSProperties;
        customBodyStrong: React.CSSProperties;
        customBody: React.CSSProperties;
        customBodyStrongCondensed: React.CSSProperties;
        customBodyCondensed: React.CSSProperties;
        customBodySm: React.CSSProperties;
        customBodyXsCondensed: React.CSSProperties;
    }
    interface TypographyVariantsOptions {
        customH1?: React.CSSProperties;
        customH2?: React.CSSProperties;
        customH3?: React.CSSProperties;
        customSubtitle?: React.CSSProperties;
        customBodyStrong?: React.CSSProperties;
        customBody?: React.CSSProperties;
        customBodyStrongCondensed?: React.CSSProperties;
        customBodyCondensed?: React.CSSProperties;
        customBodySm?: React.CSSProperties;
        customBodyXsCondensed?: React.CSSProperties;
        customDescription?: React.CSSProperties;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        customH1: true;
        customH2: true;
        customH3: true;
        customSubtitle: true;
        customBodyStrong: true;
        customBody: true;
        customBodyStrongCondensed: true;
        customBodyCondensed: true;
        customBodySm: true;
        customBodyXsCondensed: true,
        customDescription: true
    }
}

export const theme = createTheme({
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    customH1: 'h1',
                    customH2: 'h2',
                    customH3: 'h3',
                    customSubtitle: 'h4',
                    customBodyStrong: 'p',
                    customBody: 'p',
                    customBodyStrongCondensed: 'p',
                    customBodyCondensed: 'p',
                    customBodySm: 'p',
                    customBodyXsCondensed: 'p',
                    customDescription: 'p'
                }
            }
        },
    },
    shape: {
        borderRadius: 8,
    },
    typography: {
        customH1: {
            fontFamily: 'Crystal-ExpandedBold',
            fontSize: "64px",
            fontWeight: "bold",
            letterSpacing: "-0.02em",
            lineHeight: "84px",
        },
        customH2: {
            fontFamily: 'Crystal-ExpandedBold',
            fontSize: "40px",
            fontWeight: "700",
            letterSpacing: "-0.02em",
            lineHeight: "52px",
        },
        customH3: {
            fontFamily: 'Crystal-Bold',
            fontSize: "24px",
            fontWeight: "bold",
            letterSpacing: "-0.02em",
            lineHeight: "31px",
        },
        customSubtitle: {
            fontFamily: 'Crystal-Medium',
            fontSize: "24px",
            fontWeight: "500",
            lineHeight: "31px",
        },
        customBodyStrong: {
            fontFamily: 'Crystal-Bold',
            fontSize: "16px",
            fontWeight: "bold",
            lineHeight: "21px",
        },
        customBody: {
            fontFamily: 'Crystal-Regular',
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "21px",
        },
        customBodyStrongCondensed: {
            fontFamily: 'Crystal-CondensedBold',
            fontSize: "16px",
            fontWeight: "bold",
            lineHeight: "16px",
            letterSpacing: "0.05em",
            textTransform: "uppercase",
        },
        customBodyCondensed: {
            fontFamily: 'Crystal-CondensedRegular',
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "16px",
            letterSpacing: "0.05em",
            textTransform: "uppercase",
            cursor: "pointer",
        },
        customBodySm: {
            fontFamily: 'Crystal-Medium',
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "15px",
        },
        customBodyXsCondensed: {
            fontFamily: 'Crystal-CondensedRegular',
            fontSize: "11px",
            fontWeight: "500",
            lineHeight: "14px",
            letterSpacing: "0.03em",
            textTransform: "uppercase",
        },
        button: {
            fontFamily: 'Crystal-CondensedBold',
            letterSpacing: "0.05em",
            fontSize: "16px",
            lineHeight: "21px",
            fontWeight: "700",
        },
        customDescription: {
            fontFamily: 'Crystal-Regular',
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "21px",
        },
    },
    palette: {
        primary: {
            main: "#8C8C8C",
        },
        secondary: {
            main: "#C5C5C5"
        },
        success: {
            main: "#31D0AA",
        },
        error: {
            main: "#D03131"
        }
    }
})