// Third party imports
import type { AppProps } from 'next/app';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider, EmotionCache } from '@emotion/react';
import createEmotionCache from '../createEmotionCache';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

import Script from 'next/script';
import dynamic from 'next/dynamic';

// Components
import { HistoryProvider } from '../contexts/History';
const GlobalLoading = dynamic(() => import('../components/Shared/GlobalLoading'), { ssr: false });
const AuthControl = dynamic(() => import('../components/AuthControl/AuthControl'), { ssr: false });

// Global styles 
import '../App.css';
import 'swiper/css';
import 'swiper/css/navigation';
import { theme } from '../shared/theme';
import { useEffect } from 'react';
import Head from 'next/head';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache: EmotionCache;
}

function App(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  useEffect(() => {
    (async () => {
      const Sentry = (await import('@sentry/react'))
      const Tracing = (await import('@sentry/tracing'))

      Sentry.init({
        dsn: "https://e490661eeb3b47cc9230fe41d47a8485@o1296081.ingest.sentry.io/6522391",
        integrations: [new Tracing.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      });
    })()
  }, [])

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name="google-site-verification" content="4-Nj7_uEuRPsuI_VgS5TpYCrug4r6181hf8m8hwIjMk" />
        <meta name="theme-color" content={theme.palette.primary.main} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="Author" content="Tryo.io" />
      </Head>
      <Script
        id="sovrn-banner"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `var vglnk = {key: 'f5e18d84241ce5203f0678ea561273df'};
          (function(d, t) {
              var s = d.createElement(t);
                  s.type = 'text/javascript';
                  s.async = true;
                  s.src = 'https://cdn.viglink.com/api/vglnk.js';
              var r = d.getElementsByTagName(t)[0];
                  r.parentNode.insertBefore(s, r);
          }(document, 'script'));`,
        }}
      />
      <Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_G_TAG}`} />
      <Script
        id='google-analytics'
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_G_TAG}', {
            page_path: window.location.pathname,
          });
        `,
        }}
      />
      <GlobalLoading />
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <>
            <HistoryProvider>
              <AuthControl />
              <Component {...pageProps} />
            </HistoryProvider>
          </>
        </QueryClientProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
